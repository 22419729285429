<template>
<div class="text-center text-sm-right">
  <b-pagination
    :value="value"
    v-bind="$attrs"
    class="d-inline-flex"
    first-number
    last-number
    @input="input"
  >
    <template #prev-text>
      <span class="btn btn-icon btn-sm btn-light-info btn-hover-info mr-2">
        <span class="svg-icon svg-icon-info svg-icon-sm">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
      </span>
    </template>
    <template #next-text>
      <span class="btn btn-icon btn-sm btn-light-info btn-hover-info ml-2">
        <span class="svg-icon svg-icon-info svg-icon-sm">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-right.svg" />
        </span>
      </span>
    </template>
    <template #ellipsis-text>
      <span class="btn btn-icon btn-sm border-0 btn-hover-info mr-2">
        <span class="svg-icon svg-icon-info svg-icon-sm">
          <inline-svg src="/media/svg/icons/Text/Dots.svg" />
        </span>
      </span>
    </template>
    <template #page="{ page, active }">
      <span v-if="active" class="btn btn-icon btn-sm border-0 btn-hover-info active py-0">{{ page }}</span>
      <span v-else class="btn btn-icon btn-sm btn-light-info btn-hover-info py-0">{{ page }}</span>
    </template>
  </b-pagination>
</div>
</template>

<script>
export default {
  name: 'Paginations',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: Number,
  },
  methods: {
    input(e) {
      this.$emit('input', e)
    },
  },
}
</script>

<style lang="scss">
  .page-link{
    background-color: transparent!important;
    border: none;
    padding: 0 0.5rem!important;
  }
  .btn-hover-primary{
    &:hover{
      color: white !important;
    }
  }
</style>

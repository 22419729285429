import ModalConfirm from '@/components/modals/ModalConfirm'

export default {
  data() {
    return {
      search: '',
      params: {
        pagination: {
          page: 1,
          limit: 10,
        },
        order: {
          createdAt: 'desc',
        },
      },
      totalCount: null,
      items: [],
      loading: false,
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    Repo() {}, // TODO: ???
  },
  watch: {
    'params.pagination.page': {
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    onDelete(item) {
      this.$modal.show(ModalConfirm, {
        handlers: {
          onConfirm: () => {
            this.Repo.delete(item.uuid).then(() => {
              this.fetchData()
            })
          },
        },
      }, {
        height: 'auto',
        adaptive: true,
        clickToClose: false, 
      })
    },
    reFetchData() {
      this.params.pagination.page = 1
      this.fetchData()
    },
    fetchData() {
      this.loading = true
      this.Repo.list(this.params).then(({ data }) => {
        this.items = data.payload.items
        this.totalCount = data.payload.pagination.totalCount
        this.loading = false
      })
    },
  },
}

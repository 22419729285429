<template>
<div
  :class="classes"
  class="card card-custom gutter-b"
>
  <div
    v-if="hasTitleSlot || title"
    :class="headClass"
    class="card-header"
  >
    <div class="card-title">
      <slot
        v-if="hasTitleSlot"
        name="title"
      />
      <h3
        v-if="!hasTitleSlot"
        class="card-label"
      >
        {{ title }}
      </h3>
    </div>
    <div class="card-toolbar">
      <slot name="toolbar" />
    </div>
  </div>
  <div
    class="card-body"
    :class="[
      bodyClass,
      {
        'body-fit': bodyFit,
        'body-fluid': bodyFluid
      },
    ]"
  >
    <slot name="body" />
  </div>
  <div
    v-if="hasFootSlot"
    class="card-footer"
  >
    <slot name="foot" />
  </div>
</div>
</template>

<script>
export default {
  name: 'KTCard',
  props: {
    /**
     * String title
     */
    title: String,
    /**
     * Set card head size. Eg. md, lg, sm, etc.
     */
    headSize: String,
    /**
     * Set card to fluid
     */
    fluidHeight: Boolean,
    /**
     * Set card to fluid in half
     */
    fluidHalfHeight: Boolean,
    /**
     * Set overlay head
     */
    headOverlay: Boolean,
    /**
     * Set extra class for main card
     */
    cardClass: String,
    /**
     * Set extra class for card head
     */
    headClass: String,
    /**
     * Set extra class for card body
     */
    bodyClass: String,
    /**
     * Set card body to fit
     */
    bodyFit: Boolean,
    /**
     * Set card body to fluid
     */
    bodyFluid: Boolean,
    /**
     * Code examples
     */
    example: Boolean,
  },
  computed: {
    classes() {
      const cls = {
        'example example-compact': this.example,
        'height-fluid': this.fluidHeight,
        'height-fluid-half': this.fluidHalfHeight,
        'head-overlay': this.headOverlay,
      }

      cls[this.headSizeClass] = this.headSizeClass

      // append extra classes
      if (this.cardClass) cls[this.cardClass] = true

      return cls
    },
    hasTitleSlot() {
      return !!this.$slots.title
    },
    hasFootSlot() {
      return !!this.$slots.foot
    },
    headSizeClass() {
      if (!this.headSize) return false

      return `head-${this.headSize}`
    },
  },
}
</script>

<template>
<b-overlay :show="busy" variants="transparent" class="w-100" opacity="0.4" rounded="sm">
  <b-table
    class="table table-head-custom table-vertical-center"
    v-bind="$attrs"
    v-on="$listeners"
    show-empty
    responsive
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template #empty>
      <span class="text-center">{{ $t('BASE.EMPTY_DATA') }}</span>
    </template>
  </b-table>
</b-overlay>
</template>

<script>
export default {
  name: 'Table',
  props: {
    busy: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss">
  .table {
    tr:focus {
      outline: none;
      box-shadow: none;
    }
    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
  }
</style>

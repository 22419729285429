<template>
<b-form-input
  v-model="getValue"
  v-bind="$attrs"
  class="form-control form-control-solid"
  @change="$emit('change', $event)"
>
  <template v-for="(_, slot) of $scopedSlots" #[slot]="scope"><slot :name="slot" v-bind="scope" /></template>
</b-form-input>
</template>

<script>
export default {
  name: 'InputForm',
  props: {
    value: null,
  },
  computed: {
    getValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
}
</script>

<style scoped>

</style>
